<template>
  <Popup ref="popupRef" width="500px" title="查看凭证" :showConfirm="false" :showCancel="false" @close="resetData">
    <div class="file-list">
      <div class="file-item" v-for="(item, index) in evidenceList" :key="index">
        <div class="flex items-center">
          <img class="icon-file" src="@/assets/images/fujian.png">
          <span>{{item.name}}</span>
        </div>
        <a class="btn-download" target="_blank" :href="item.url" :download="item.name">
          <span>下载</span>
          <img class="icon-download" src="@/assets/images/download.png">
        </a>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      evidenceList: []
    }
  },
  methods: {
    open(option) {
      if(option?.evidence) {
        const evidenceList = option.evidence
        evidenceList.forEach(item => {
          ossKeyToUrl({key: item.docAddress}).then(res => {
            this.evidenceList.push({
              url: res.url,
              name: item.docName
            })
          })
        })
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.evidenceList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.icon-file {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.btn-download {
  display: flex;
  align-items: center;
  color: #08C8BD;
  margin-left: 16px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}
</style>